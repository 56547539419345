import React from "react";
import styled from "styled-components";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 500px;
  max-width: 100%;
  background-color: #fff;
`;

const FeedbackTitle = styled.h1`
  margin: 8px;
`;

const FeedbackText = styled.p`
  margin: 8px;
`;

const VerifyEmailSuccess = () => {
  return (
    <>
      <Navbar />
      <FeedbackContainer>
        <FeedbackTitle>Afmelden mislukt</FeedbackTitle>
        <FeedbackText>Neem contact op voor meer informatie</FeedbackText>
      </FeedbackContainer>
      <Footer />
    </>
  );
};

export default VerifyEmailSuccess